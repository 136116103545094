<template>
  <div class="assetSimple">
    <h-stack class="checkAsset" align-items="flex-start">
      <div class="checkAssetChooseBGView">
        <div class="checkAssetChoose">
          <el-button class="title" size="mini" type="text">开始时间</el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" class="checkAssetChooseSelect">
            <div class="block" style="width: 100%;">
              <el-date-picker
                  size="mini"
                  @focus="focus"
                  value-format="timestamp"
                  v-model="startTime"
                  style="width: 100%;"
                  type="datetime"
                  placeholder="开始时间"
                  :editable="false"
              >
              </el-date-picker>
            </div>
          </h-stack>
        </div>
        <div class="checkAssetChoose">
          <el-button class="title" size="mini" type="text">结束时间</el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" class="checkAssetChooseSelect">
            <div class="block" style="width: 100%;">
              <el-date-picker
                  size="mini"
                  @focus="focus"
                  value-format="timestamp"
                  v-model="endTime"
                  style="width: 100%;"
                  type="datetime"
                  placeholder="结束时间"
                  :editable="false"
              >
              </el-date-picker>
            </div>
          </h-stack>
        </div>
        <div class="checkAssetChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('asset')"
                     :loading="loadCategory"
          >资产分类
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start" class="checkAssetChooseSelect">
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectAssetCategoryList" @click="showSelectView('asset')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>
        <div class="checkAssetChoose">
          <el-button style="height: 30px" size="mini" type="primary" @click="showTree('org')"
                     :loading="loadOrg"
          >科室
          </el-button>
          <h-stack flex-wrap="wrap" justify-content="flex-start" align-items="flex-start" class="checkAssetChooseSelect">
            <div class="checkAssetChooseSelectItem" v-for="(item) in selectOrgList" @click="showSelectView('org')">
              {{ item.name }}
            </div>
          </h-stack>
        </div>
      </div>
      <v-stack justify-content="center" style="margin-right: 5px;margin-top: 5px">
        <button style="outline: none;margin-top: 5px;margin-bottom: 5px;background-color: #85ce61;height: 63px;"
                class="checkAssetTitle"
                @click="assetCheck(selectAssetCategoryList, selectOrgList, orgList, '')"
        >盘点
        </button>
      </v-stack>
    </h-stack>

    <vue-tree
        ref="vueTree"
        @dialogClose="dialogClose"
        @dialogSure="dialogSure"
        :expanded-keys="defaultCheckedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :dialog-title="dialogType=='asset' ? '资产分类' : '科室'"
        :data-list="dataList"
        :dialog-visible="dialogVisible"
    ></vue-tree>


    <el-drawer
        :modal="false"
        size="calc(100% - 50px)"
        :visible.sync="showDetail"
        :with-header="false"
        direction="btt">
      <asset-simple-table ref="assetTable" :detail-data="detailData" ></asset-simple-table>
    </el-drawer>
  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import VueTree from '@/components/tree/vueTree'
import VStack from '@/components/vStack/vStack'
import Cell_title_detail from '@/components/cell/cell_title_detail/cell_title_detail'
import CheckAssetRecord from '@/views/checkAsset/checkAssetRecord/checkAssetRecord'
import AssetSimpleTable from '@/views/assetSimple/assetSimpleTable/assetSimpleTable'
import mixin from '@/views/assetSimple/mixin/mixin'

export default {
  name: 'assetSimple',
  mixins: [mixin],
  components: { AssetSimpleTable, CheckAssetRecord, Cell_title_detail, VStack, VueTree, HStack },
  data() {
    return {}
  },
  watch: {},
  mounted() {
    this.$nextTick(() => {
      this.getAllData()
    })
  },
  computed: {
    dataList: function() {
      let list = [];
      if (this.dialogType == 'asset') {
        list = this.assetCategoryList;
      } else if (this.dialogType == 'org') {
        list = this.orgList;
      }
      return list
    },
    defaultCheckedKeys: function() {
      let list = []
      let selectList = []
      if (this.dialogType == 'asset') {
        selectList = this.selectAssetCategoryList;
      } else if (this.dialogType == 'org') {
        selectList = this.selectOrgList;
      }
      for (let i = 0; i < selectList.length; i++) {
        let asset = selectList[i]
        if (list.indexOf(asset.id) >= 0) {
          continue
        }
        list.push(asset.id)
      }
      return list
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'login') {
      next(true)
      return
    }
    let leave = () => {
      if (this.showDetail) {
        this.showDetail = false
        next(false)
        return
      }
      next(true)
    }

    if (typeof this.$refs.assetTable == 'undefined') {
      leave()
      return
    }
    let res = this.$refs.assetTable.beforeRouteLeave()
    if (res.result == false) {
      next(false)
      return
    }
    leave()
  },
  methods: {
    showTree:function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogType = type;
      this.dialogVisible = true;
      this.$refs.vueTree.showAllView();
    },
    showSelectView:function(type) {
      if (typeof this.$refs.vueTree == 'undefined') {
        return
      }
      this.dialogType = type;
      this.dialogVisible = true;
      this.$refs.vueTree.showAllView();
      this.$nextTick(()=>{
        this.$refs.vueTree.showSelectView();
      })
    },
    currentChange: function(index) {
      this.page = index
      this.loadRecord = true
      this.getDeviceInventoryList(false).then(res => {
        this.tableDataRecord = res.list
        this.loadRecord = false
      })
    },
    checkAssetRecord: function(res) {
      let row = res.row
      for (const rowKey in row) {
        let value = row[rowKey]
        if (typeof value == 'undefined' || value == null) {
          value = ''
        }
        this.detailData[rowKey] = value
      }
      this.assetCheck(row.categoryList, row.tenantList, this.orgList, row.id)

    },
    focus: function() {
      this.$nextTick(() => {
        let inputs = document.getElementsByClassName('el-input__inner')
        for (let i = 0; i < inputs.length; i++) {
          let input = inputs[i]
          input.setAttribute('readonly', 'readonly')
        }

        let bodys = document.getElementsByClassName('el-picker-panel')
        for (let i = 0; i < bodys.length; i++) {
          let b = bodys[i]
          b.style.transform = 'translate(-20px, 0px)'
        }
      })
    },
    assetCheck: function(selectAssetCategoryList, selectOrgList, orgList, id) {
      let categoryId = ''
      let categoryName = ''
      for (let i = 0; i < selectAssetCategoryList.length; i++) {
        let asset = selectAssetCategoryList[i]
        categoryId = categoryId + (categoryId == '' ? '' : ',') + asset.id
        categoryName = categoryName + (categoryName == '' ? '' : ',') + asset.name
      }

      let tenantId = ''
      let tenantName = ''

      for (let i = 0; i < selectOrgList.length; i++) {
        let org = selectOrgList[i]
        tenantId = tenantId + (tenantId == '' ? '' : ',') + org.id
        tenantName = tenantName + (tenantName == '' ? '' : ',') + org.name
      }

      if (tenantId == '' && orgList.length > 0) {
        let org = orgList[0]
        tenantId = org.id
      }
      this.detailData.categoryId = categoryId
      this.detailData.tenantId = tenantId
      this.detailData.id = id
      this.detailData.categoryName = categoryName
      this.detailData.tenantName = tenantName
      this.showDetail = true
      this.$nextTick(()=>{
        if (typeof this.$refs.assetTable == 'undefined') {
          return
        }
        setTimeout(() => {
          this.$refs.assetTable.assetCheck()
        }, 500)
      })
    },
    dialogClose: function(res) {
      this.dialogVisible = res.dialogVisible
    },
    dialogSure: function(res) {
      this.dialogVisible = res.dialogVisible
      if (this.dialogType == 'asset') {
        this.selectAssetCategoryList = res.nodes
      } else if (this.dialogType == 'org') {
        this.selectOrgList = res.nodes
      }
    }

  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.assetSimple {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;


  .checkAsset {
    width: 100%;
    background-color: white;


    .checkAssetTitle {
      text-align: center;
      width: 70px;
      height: 30px;
      line-height: 30px;
      float: left;
      font-size: 14px;
      color: white;
      border-radius: 5px;
      background-color: $color_primary;
      border: none;
    }

    .title {
      width: 70px;
      height: 30px;
    }

    .checkAssetChooseBGView {
      width: calc(100% - 60px);
      margin-top: 5px;
      margin-bottom: 5px;

      .checkAssetDatePicker {
        width: 90%;
      }

      .checkAssetChoose {
        float: left;
        width: calc(100% - 20px);
        transform: translate(10px, 0px);
        margin-top: 5px;


        .checkAssetChooseSelect {
          float: right;
          width: calc(100% - 95px);
          margin-left: 5px;
          overflow: scroll;
          max-height: 30px;


          .checkAssetChooseSelectItem {
            padding-left: 5px;
            padding-right: 5px;
            word-wrap: break-word;
            margin-left: 5px;
            font-size: 13px;
            color: $color_primary;
            line-height: 28px;
            min-height: 28px;
            border-radius: 5px;
            margin-bottom: 2px;
          }
        }
      }
    }

    .checkAssetAction {
      width: 60px;
      height: 60px;
      margin-right: 5px;
    }
  }

  .checkAssetList {
    margin-top: 5px;
    width: 100%;
    height: calc(100% - 125px);
    background-color: white;
    overflow: scroll;

    .checkAssetHead {
      width: 100%;
      height: 30px;
      font-size: 15px;
      text-align: center;
      line-height: 30px;
      color: $color_primary;
    }


  }

}

</style>
