<template>
  <el-dialog
      :modal-append-to-body="false"
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="80%"
      class="diaLog"
      center
      :before-close="handleClose"
  >
    <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input"
        clearable
    >
    </el-input>
    <div style="height: 20px"></div>
    <el-tree
        class="vueTree"
        :default-expanded-keys="tempSelect"
        :default-checked-keys="tempSelect"
        :data="treeDataList"
        show-checkbox
        node-key="id"
        ref="tree"
        :filter-node-method="filterNode"
        @check-change="handleCheckChange"
        :props="defaultProps"
    >
    </el-tree>
    <div style="width: 100%;height: 28px;">
      <el-button style="float: right" size="mini" type="text" @click="changeShow">
        {{ !showSelected ? '查看已选择' : '查看全部选择' }}
      </el-button>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="sure">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'vueTree',
  props: {
    expandedKeys: {
      type: Array,
      default: () => {
        return ['']
      }
    },

    defaultCheckedKeys: {
      type: Array,
      default: () => {
        return ['']
      }
    },
    dialogTitle: {
      type: String,
      default: '提示'
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dataList: {
      type: Array,
      default: () => {
        return []
      }
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          label: 'name'
        }
      }
    }
  },
  watch: {
    dataList: {
      handler(value) {
        this.$nextTick(()=>{

        })
      },
      immediate: true,
      deep: true
    },
    defaultCheckedKeys: {
      handler(value) {
        let newTempSelect = [];
        for (let i = 0; i < value.length; i++) {
          newTempSelect.push(value[i])
        }
        this.tempSelect = newTempSelect;
      },
      immediate: true,
      deep: true
    },
    dialogVisible: {
      handler(value) {
        if (this.changeStyle || value == false) {
          return
        }
        this.$nextTick(() => {
          this.updateStyle()
        })
      },
      immediate: true,
      deep: true
    },
    input: {
      handler(value) {
        if (typeof this.$refs.tree == 'undefined') {
          return
        }
        this.$refs.tree.filter(value)
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      input: '',
      changeStyle: false,
      tempSelect: [],
      tempDataList:[],
      showSelected: false
    }
  },
  computed: {
    treeDataList: function() {
      return this.showSelected ? this.tempDataList : this.dataList
    }
  },
  mounted() {

  },
  methods: {
    showAllView:function() {
      this.input = ''
      this.getSelectNode();
      this.showSelected = false;
    },
    showSelectView:function() {
      this.input = ''
      this.tempDataList = this.getSelectNode();
      this.showSelected = true;
    },
    changeShow: function() {
      if (this.showSelected == false) {
        this.showSelectView();
      } else {
        this.showAllView();
      }
    },
    updateStyle: function() {
      let doms = document.getElementsByClassName('el-dialog__body')
      for (let i = 0; i < doms.length; i++) {
        let d = doms[i]
        d.style.padding = '0px 25px 0px 30px'
      }
      this.changeStyle = true
    },
    //优化之后的代码 不管有几级都可以适用,不过用了递归，量太大还是会崩溃，这个后续需要优化
    filterNode(value, data, node) {
      if (!value) {
        return true
      }
      let level = node.level
      let _array = [] //这里使用数组存储 只是为了存储值。
      this.getReturnNode(node, _array, value)
      let result = false
      _array.forEach(item => {
        result = result || item
      })
      return result
    },
    getReturnNode(node, _array, value) {
      let isPass = node.data && node.data.name && node.data.name.indexOf(value) !== -1
      isPass ? _array.push(isPass) : ''
      this.index++
      if (!isPass && node.level != 1 && node.parent) {
        this.getReturnNode(node.parent, _array, value)
      }
    },
    handleCheckChange(data, checked, indeterminate) {

    },
    close: function() {
      this.input = ''
      this.$emit('dialogClose', { dialogVisible: false })
    },
    getSimpleCheckedNodes(store) {
      const checkedNodes = []
      const traverse = function(node) {
        const childNodes = node.root ? node.root.childNodes : node.childNodes

        childNodes.forEach(child => {
          if (child.checked) {
            checkedNodes.push(child.data)
          }
          if (child.indeterminate) {
            traverse(child)
          }
        })
      }
      traverse(store)
      return checkedNodes
    },
    getSelectNode: function() {
      let nodes = []
      if (typeof this.$refs.tree != 'undefined') {
        nodes = this.getSimpleCheckedNodes(this.$refs.tree.store)
      }
      let selectNodes = []
      let newTempSelect = [];
      for (let i = 0; i < nodes.length; i++) {
        let n = nodes[i]
        selectNodes.push(n)
        if (newTempSelect.indexOf(n.id) >= 0) {
          continue
        }
        newTempSelect.push(n.id)
      }
      this.tempSelect = newTempSelect;

      return selectNodes
    },
    sure: function() {
      this.input = ''
      let res = { dialogVisible: false, nodes: [], nodeIds: '' }
      let selectNodes = this.getSelectNode();
      res.nodes = selectNodes
      this.$emit('dialogSure', res)
      this.showSelected = false;
    },
    handleClose: function() {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>

.diaLog {
  z-index: 9999;

  .vueTree {
    overflow: scroll;
    max-height: 40vh;
  }
}

.animation {
  -webkit-animation: animation 1s; /* Safari and Chrome */
}

@-webkit-keyframes animation /* Safari 与 Chrome */
{
  0% {
    transform: rotateY(0deg)
  }
  50% {
    transform: rotateY(180deg)
  }
  100% {
    transform: rotateY(360deg)
  }
}

::v-deep .vueTree .el-tree-node__label {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap !important
}

::v-deep .vueTree .el-tree-node__content {
  height: auto;
}


</style>
