<template>
  <div class="checkAssetView">
    <div>
      <div class="title">所属科室</div>
      <div class="content">{{ tenantTitle }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div class="title">盘点时间</div>
      <div class="content">{{ timeStr }}</div>
      <div style="clear: both"></div>
    </div>
    <div>
      <div>
        <div class="subItem">
          <div class="subTitle">预盘量</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'total') == false ? '-' : dataInfo['total'] }}</div>
        </div>
        <div class="subItem">
          <div class="subTitle">实盘量</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'realityNum') == false ? '-' : dataInfo['realityNum'] }}</div>
        </div>
        <div class="subItem">
          <div class="subTitle">盘亏(盈)</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'notCountedNum') == false ? '-' : dataInfo['notCountedNum'] }}</div>
        </div>
      </div>
      <div>
        <div class="subItem">
          <div class="subTitle">维修</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'serviceNum') == false ? '-' : dataInfo['serviceNum'] }}</div>
        </div>
        <div class="subItem">
          <div class="subTitle">报障</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'eliminationNum') == false ? '-' : dataInfo['eliminationNum'] }}</div>
        </div>
        <div class="subItem">
          <div class="subTitle">总使用率</div>
          <div class="subContent">{{ $valueIsExist(dataInfo, 'usageRate') == false ? '-' : (dataInfo['usageRate'] + '%' )}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'checkAssetView',
  props: {
    dataInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    timeStr() {
      return this.formatDate(this.dataInfo['createDate'])
    },
    tenantTitle() {
      if (this.$valueIsExist(this.dataInfo, 'tenantTitle') == false) {
        return ''
      }
      return this.dataInfo['tenantTitle']

    }
  }
}
</script>

<style lang="scss" scoped>

.checkAssetView {

  .title {
    width: 60px;
    text-align: right;
    height: 30px;
    line-height: 25px;
    font-size: 13px;
    float: left;
  }

  .content {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 80px);
    text-align: right;
    line-height: 25px;
    font-size: 13px;
    text-align: left;
    float: right;
    word-break: break-all;
  }

  .subItem {
    width: 33.333%;
    float: left;

    .subTitle {
      width: 60px;
      text-align: right;
      height: 30px;
      line-height: 25px;
      font-size: 13px;
      float: left;
    }

    .subContent {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 70px);
      text-align: right;
      line-height: 25px;
      font-size: 13px;
      text-align: left;
      float: right;
      word-break: break-all;
    }

  }

}

</style>
