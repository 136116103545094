let mixin = {
  data: function() {
    return {
      page: 1,
      pageSize: 50,
      totalCount: 0,
      dialogVisible: false,
      dialogType: '',
      assetCategoryList: [], // 资产分类
      selectAssetCategoryList: [], // 被选择的资产分类
      orgList: [], // 科室
      selectOrgList: [], // 被选择的科室
      loadCategory: true,
      loadOrg: true,
      loadRecord: true,
      activeName: 'checkRecord', // checkRecord 盘点记录 checkResult 盘点结果
      tableData: [], // 盘点数据
      tableDataRecord: [], // 盘点历史数据
      shouldSelectAll: false, // 科室 资产分类是否能全选
      showAssetDetail: false,
      startTime: new Date(new Date(new Date().toLocaleDateString()).getTime()).getTime(),
      endTime: new Date().getTime(),
      showDetail: false,
      detailData: {
        brandId: '',
        brandList: [],
        categoryId: '',
        categoryList: [],
        createDate: 0,
        customerId: '',
        customerName: '',
        del: false,
        eliminationNum: 1,
        id: '',
        idleRate: 0,
        inventoryUserName: '',
        lostEquipment: 0,
        modelId: '',
        modelList: [],
        noEnableNum: 0,
        realityNum: 0,
        serialNumber: '',
        serviceNum: 0,
        tenantId: '',
        tenantList: [],
        total: 0,
        type: 0,
        usageRate: 0,
        username: '',
        categoryName: '',
        tenantName: ''
      }
    }
  },
  methods: {
    getAllData: function() {
      this.loadCategory = true
      this.loadOrg = true
      this.loadRecord = true
      Promise.all([this.getAssetCategory(), this.getOrg()]).then(res => {
        let assetRes = res[0]
        this.assetCategoryList = assetRes.list
        this.selectAssetCategoryList = assetRes.selectList
        this.loadCategory = false

        let orgRes = res[1]
        this.orgList = orgRes.list
        this.selectOrgList = orgRes.selectList
        this.loadOrg = false
        this.loadRecord = false

        // this.getDeviceInventoryList(false).then(res => {
        //   this.tableDataRecord = res.list
        //   this.totalCount = res.totalCount
        //   this.loadRecord = false
        // })
      })
    },
    // 获取子机构科室
    getOrg: function() {
      return new Promise(resolve => {
        this.orgList = []
        this.$api.userInfo.getListTreeTenantAuthority({}).then(res => {
          let jsonStr = JSON.stringify(res.data)
          jsonStr = jsonStr.replace(/title/g, 'name')
          jsonStr = jsonStr.replace(/child/g, 'children')

          let list = []
          let selectList = []

          try {
            list = JSON.parse(jsonStr)
            let userInfo = this.$store.getters.userInfo
            let tenantId = userInfo.tenantId
            for (let i = 0; i < list.length; i++) {
              let org = list[i]
              if (org.id == tenantId) {
                selectList.push(org)
                if (i == 0) {
                  org.id = '';
                }
                break
              }
            }
          } catch (e) {

          }
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取资产分类
    getAssetCategory: function() {
      return new Promise(resolve => {
        this.assetCategoryList = []
        this.$api.assetModule.getDeviceCategoryTree({}).then(res => {
          let list = [{
            id: '',
            name: '全部资产分类',
            parentId: '',
            parentName: '',
            children: res.data
          }]
          let selectList = list
          resolve({ list: list, selectList: selectList })
        }).catch(e => {
          resolve({ list: [], selectList: [] })
        })
      })
    },
    // 获取盘点记录
    getDeviceInventoryList: function() {
      return new Promise(resolve => {
        this.tableDataRecord = []
        let categoryId = ''
        for (let i = 0; i < this.selectAssetCategoryList.length; i++) {
          let asset = this.selectAssetCategoryList[i]
          categoryId = categoryId + (categoryId == '' ? '' : ',') + asset.id
        }
        let tenantId = ''
        for (let i = 0; i < this.selectOrgList.length; i++) {
          let org = this.selectOrgList[i]
          tenantId = tenantId + (tenantId == '' ? '' : ',') + org.id
        }
        let startTime = '';

        let endTime = '';

        let config = {
          isTenantIdChild: 1,
          isCategoryIdChild: 1,
          startTime: startTime,
          endTime: endTime,
          categoryId: categoryId,
          tenantId: tenantId,
          del: false,
          order: '',
          orderType: '',
          page: this.page,
          pageSize: this.pageSize,
          type: ''
        }
        this.$api.assetModule.getDeviceInventoryList(config).then(res => {
          let list = []
          if (res.code == 200) {
            list = res.data
          }
          let totalCount = res.totalCount
          resolve({ list: list, totalCount: totalCount })
        }).catch(e => {
          resolve({ list: [], totalCount: 0 })
        })
      })
    }
  }
}

export default mixin
