<!--<template>-->
<!--  <el-table-->
<!--      v-loading="loading"-->
<!--      border-->
<!--      stripe-->
<!--      width="100%"-->
<!--      :height="height"-->
<!--      :data="tableData"-->
<!--      class="checkAssetList" @row-click="rowClick"-->
<!--  >-->
<!--    <el-table-column-->
<!--        fixed-->
<!--        header-align="center"-->
<!--        type="index"-->
<!--        :index="indexMethod"-->
<!--        label="序号"-->
<!--        width="50"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        fixed-->
<!--        prop="tenantTitle"-->
<!--        label="所属科室"-->
<!--        :width="'120px'"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="total"-->
<!--        label="预盘量"-->
<!--        :width="width"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="realityNum"-->
<!--        label="实盘量"-->
<!--        :width="width"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ (getNum(scope.row.realityNum)) }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="realityNum"-->
<!--        label="盘亏(盈)"-->
<!--        :width="width"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ (getNum(scope.row.realityNum) - getNum(scope.row.total)) }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="serviceNum"-->
<!--        label="维修"-->
<!--        :width="width"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="eliminationNum"-->
<!--        label="报障"-->
<!--        :width="width"-->
<!--    >-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="usageRate"-->
<!--        label="总使用率"-->
<!--        :width="width"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.usageRate + '%' }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="idleRate"-->
<!--        label="总闲置率"-->
<!--        :width="width"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.idleRate + '%' }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->

<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="time"-->
<!--        label="盘点时间"-->
<!--        width="160px"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ formatDate(scope.row.createDate) }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--    <el-table-column-->
<!--        header-align="center"-->
<!--        prop="type"-->
<!--        label="状态"-->
<!--        :width="width"-->
<!--    >-->
<!--      <template slot-scope="scope">-->
<!--        <span>{{ scope.row.type == 0 ? '手动' : '自动' }}</span>-->
<!--      </template>-->
<!--    </el-table-column>-->
<!--  </el-table>-->
<!--</template>-->

<template>
  <el-table
      :show-header="false"
      v-loading="loading"
      border
      stripe
      width="100%"
      :height="height"
      :data="tableData"
      class="checkAssetList" @row-click="rowClick"
  >
    <el-table-column
        header-align="center"
        prop="realityNum"
        label=""
    >
      <template slot-scope="scope">
        <check-asset-view :data-info="scope.row"></check-asset-view>
      </template>
    </el-table-column>

  </el-table>
</template>

<script>
import CheckAssetView from '@/views/checkAsset/checkAssetRecord/checkAssetView'
export default {
  name: 'checkAssetRecord',
  components: { CheckAssetView },
  props: {
    height: {
      type: String,
      default: 'calc(100% - 250px)'
    },
    tableData: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      width: 'calc(100%/8) <= 120px ? 120px : calc(100%/8)'
    }
  },
  computed: {},
  methods: {
    indexMethod: function(index) {
      return index + 1
    },
    getNum: function(value) {
      if (isNaN(value) || isNaN(parseInt(value))) {
        return 0
      }
      return parseInt(value)
    },

    belongOrg: function(item) {
      let orgStr = ''
      let tenantList = []
      if (typeof item['tenantList'] != 'undefined' && item['tenantList'] != null) {
        tenantList = item['tenantList']
      }
      for (let i = 0; i < tenantList.length; i++) {
        let orgInfo = tenantList[i]
        orgStr = orgStr + (orgStr == '' ? '' : '/') + orgInfo.name
      }
      return orgStr
    },
    rowClick: function(row, event, column) {
      this.$emit('checkAssetRecord', { row, event, column })

    },
    deviceStatus: function(status) {
      if (status == 1) {
        return '开机'
      } else if (status == 2) {
        return '脱落'
      } else if (status == 3) {
        return '报障'
      } else if (status == 4) {
        return '信号丢失'
      } else if (status == 0) {
        return '关机'
      } else if (status == -1) {
        return '未启用'
      } else if (status == -2) {
        return '维修'
      }
      return '-'
    }
  }
}
</script>


<style lang="scss" scoped>

.checkAssetList {

}


</style>


<style lang="scss">


</style>
